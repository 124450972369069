import { useCallback, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compact } from 'lodash-es';
import millify from 'millify';
import { Navigate, useNavigate, useParams } from 'react-router';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faClose, faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEllipsis } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPen } from '@soundxyz/font-awesome/pro-regular-svg-icons';

import { faTrash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faGlobe } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEye } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faEyeSlash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { CampaignStatusBanner } from '../components/banner/CampaignStatusBanner';
import { BackButton } from '../components/buttons/BackButton';
import { Button } from '../components/buttons/Button';
import { useCampaignForm } from '../components/campaign/useCampaignForm';
import { Image } from '../components/common/Image';
import { Text } from '../components/common/Text';
import { View } from '../components/common/View';
import { ErrorView } from '../components/error/ErrorView';
import { DefaultLayout } from '../components/layouts/DefaultLayout';
import { LoadingSkeleton } from '../components/loading/LoadingSkeleton';
import { SkeletonUserRow } from '../components/user/UserRow';
import { EmptyStateView } from '../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../constants/bottomsheetConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useBottomsheetContainer } from '../contexts/BottomsheetContext';
import { useInfiniteQuery, useQuery } from '../graphql/client';
import type { UserRowFragmentDoc } from '../graphql/generated';
import {
  CampaignInsightsDocument,
  InsightHeaderFragmentDoc,
  ReleaseCampaignStatus,
} from '../graphql/generated';
import { CampaignByIdDocument, type FragmentType, getFragment } from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useCopy } from '../hooks/useCopy';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';
import type { ActionBottomsheetProps } from '../types/bottomsheetTypes';
import { generateShareLink } from '../utils/linkUtils';
import { artistNavigationPath } from '../utils/navigationUtils';
import { formatDateString } from '../utils/textUtils';
import { MyMemberRow } from './settings/MySubscribersPage';

gql(/* GraphQL */ `
  query CampaignById($id: UUID!, $artistHandle: String!) {
    releaseCampaignByIdOrSlug(id: $id, artistHandle: $artistHandle) {
      id
      linkValue
      status
      ...InsightHeader
    }
  }

  query CampaignInsights($id: UUID!, $artistHandle: String!, $after: String, $first: Int) {
    allArtistMembershipReceipts(
      artistHandle: $artistHandle
      releaseCampaignIds: [$id]
      after: $after
      first: $first
    ) {
      edges {
        cursor
        node {
          id
          user {
            id
            ...userRow
          }
          membership {
            createdAt
            vaultSubscription {
              id
              createdAt
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }

  fragment InsightHeader on ReleaseCampaign {
    id
    title
    initialReleaseImageUrl
    coverImage {
      id
      url
    }
    description
    releaseDate
    newSubscriptionCount
    receiptCount
    artist {
      id
      profileImage {
        id
        url
      }
    }
  }
`);

const LIMIT = 20;

export function CampaignInsightsPage() {
  const { campaignId } = useParams();
  const { artistHandle } = useArtistHandle();

  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();

  const navigate = useNavigate();

  const { isDesktop } = useWindow();

  const {
    deleteCampaign,
    releaseCampaignDeleting,
    deactivateCampaign,
    releaseCampaignDeactivating,
    reactivateCampaign,
    releaseCampaignReactivating,
  } = useCampaignForm();

  useVaultTheme();

  const {
    data,
    isLoading: isCampaignLoading,
    isError: isCampaignError,
    refetch: refetchCampaign,
  } = useQuery(CampaignByIdDocument, {
    variables: !!artistHandle &&
      !!campaignId && {
        id: campaignId,
        artistHandle,
      },
    staleTime: 0,
    select: data => data.data.releaseCampaignByIdOrSlug,
  });

  const {
    orderedList: members,
    isError,
    isLoading,
    refetch,
  } = useInfiniteQuery(CampaignInsightsDocument, {
    filterQueryKey: {
      artistHandle,
      campaignId,
    },
    staleTime: 0,
    getNextPageParam: ({ data }) => {
      return (
        data.allArtistMembershipReceipts.pageInfo.hasNextPage && {
          after: data.allArtistMembershipReceipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      !!campaignId &&
      (({ pageParam }) => {
        return {
          id: campaignId,
          artistHandle,
          after: pageParam?.after ?? null,
          first: LIMIT,
        };
      }),
    list: ({ allArtistMembershipReceipts }) => {
      return allArtistMembershipReceipts.edges.map(({ node }) => node);
    },
    uniq: ({ id }) => id,
  });

  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: data?.linkValue ? `/s/${data?.linkValue}` : '/',
      inviteCode: loggedInUser?.inviteCode,
    });
  }, [artistHandle, data?.linkValue, loggedInUser?.inviteCode]);

  const { copy } = useCopy({ successMessage: 'Copied to clipboard', text: link });

  const isOwner =
    !!artistHandle &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const buttons: ActionBottomsheetProps['buttons'] = useMemo(() => {
    const buttonClassName =
      'border-b-vault_text/5 bg-vault_text/10 hover:bg-vault_text/20 text-vault_text ease-in-out duration-300 transition-all md2:h-[45px] text-[16px]/[20px] justify-between gap-4';

    return compact([
      campaignId && {
        label: 'View public page',
        trailingIcon: faGlobe,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          window.open(link, '_blank');
          closeBottomsheet();
        },
      },
      campaignId && {
        label: 'Edit',
        trailingIcon: faPen,
        type: 'secondary',
        className: buttonClassName,
        onClick: () => {
          navigate(artistNavigationPath(artistHandle, `/campaign/edit/${campaignId}`));
          closeBottomsheet();
        },
      },
      {
        label: 'Copy link',
        className: buttonClassName,
        trailingIcon: faLink,
        type: 'secondary',
        onClick: () => {
          copy();
          closeBottomsheet();
        },
      },
      campaignId &&
        data?.status && {
          label: data.status === ReleaseCampaignStatus.Active ? 'Make private' : 'Make public',
          trailingIcon: data.status === ReleaseCampaignStatus.Active ? faEyeSlash : faEye,
          type: 'secondary',
          className: buttonClassName,
          loading: releaseCampaignDeactivating || releaseCampaignReactivating,
          onClick: async () => {
            openBottomsheet({
              type: 'CONFIRMATION',
              confirmationBottomsheetProps: {
                subText: `Are you sure you want to make this event ${data.status === ReleaseCampaignStatus.Active ? 'private' : 'public'}?`,
                confirmButtonText: 'Confirm',
                onConfirm: async () => {
                  data.status === ReleaseCampaignStatus.Active
                    ? await deactivateCampaign(campaignId)
                    : await reactivateCampaign(campaignId);
                  closeBottomsheet();
                },
              },
            });
          },
        },
      campaignId && {
        label: 'Delete',
        trailingIcon: faTrash,
        type: 'secondary',
        className: buttonClassName,
        loading: releaseCampaignDeleting,
        onClick: async () => {
          openBottomsheet({
            type: 'CONFIRMATION',
            confirmationBottomsheetProps: {
              subText: 'Are you sure you want to delete this event?',
              confirmButtonText: 'Delete',
              onConfirm: async () => {
                await deleteCampaign(campaignId);
                closeBottomsheet();
                navigate(artistNavigationPath(artistHandle, '/dashboard'));
              },
            },
          });
        },
      },
    ]);
  }, [
    campaignId,
    data?.status,
    releaseCampaignDeactivating,
    releaseCampaignReactivating,
    releaseCampaignDeleting,
    link,
    closeBottomsheet,
    navigate,
    artistHandle,
    copy,
    openBottomsheet,
    deactivateCampaign,
    reactivateCampaign,
    deleteCampaign,
  ]);

  const renderItem = useCallback(
    (
      _index: number,
      {
        id,
        user,
        membership: { vaultSubscription, createdAt },
      }: {
        id: string;
        membership: {
          vaultSubscription: {
            createdAt: string;
          } | null;
          createdAt: string;
        };
        user: { id: string } & FragmentType<UserRowFragmentDoc>;
      },
    ) => {
      return (
        <MyMemberRow
          id={id}
          createdAt={vaultSubscription?.createdAt ?? createdAt}
          user={user}
          phone={null}
          email={null}
          userLocation={null}
          joinedViaReferralCode={null}
          isTrial={null}
          vaultSubscriptionSourceText={null}
          vaultSubscriptionSourceType={null}
          artistMembership={null}
          className="pb-6"
          withVaultTheme
        />
      );
    },
    [],
  );

  const Header = useCallback(() => {
    if (isCampaignLoading || data == null) {
      return <InsightHeaderSkeleton />;
    }

    return <InsightHeader campaignData={data} campaignLink={link} />;
  }, [data, isCampaignLoading, link]);

  const EmptyState = useCallback(() => {
    if (isCampaignLoading || isLoading) {
      return (
        <>
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
          <SkeletonUserRow profileImageClassName="w-[64px] h-[64px] rounded-full" withVaultTheme />
        </>
      );
    }

    if (isCampaignError || isError) {
      return (
        <ErrorView
          onRetryClick={isCampaignError ? refetchCampaign : refetch}
          loggingType="campaign_insights_page_retry"
          withVaultTheme
        />
      );
    }

    return (
      <EmptyStateView
        className="mt-8"
        title="No insights yet"
        subtitle="Share your release campaign to see insights here"
        buttonText="Share campaign"
        onButtonClick={data != null ? copy : undefined}
        withVaultTheme
      />
    );
  }, [
    copy,
    data,
    isCampaignError,
    isCampaignLoading,
    isError,
    isLoading,
    refetch,
    refetchCampaign,
  ]);

  if (loginStatus !== LoginStatus.LOADING && !isOwner) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  return (
    <DefaultLayout
      withBottomNavigator={false}
      vaultId={undefined}
      messageChannelId={undefined}
      hasChatReadAccess={undefined}
      showBorder
      showRoundedTop
      withVaultTheme
      stretch
      banner={
        <CampaignStatusBanner
          campaignId={campaignId}
          status={data?.status}
          onMakePublic={() => {
            if (campaignId) reactivateCampaign(campaignId);
          }}
        />
      }
      headerLeft={<BackButton icon={!isDesktop ? faClose : undefined} withVaultTheme />}
      headerCenter={
        <Text className="font-title text-[18px]/[22px] font-medium text-vault_text">Insights</Text>
      }
      headerRight={
        <Button
          label=""
          iconOnly
          leadingIcon={faEllipsis}
          onClick={() => {
            openBottomsheet({
              type: BOTTOMSHEET_TYPES.ACTION,
              actionBottomsheetProps: {
                buttons,
                className: 'w-full md2:w-80',
                withVaultTheme: true,
              },
            });
          }}
          className="text-[24px] text-vault_text outline-none"
        />
      }
      contentClassName="md2:bg-vault_text/3"
      headerClassName="md2:bg-vault_text/3"
      isHeaderTransparent
    >
      <Virtuoso
        data={members}
        itemContent={renderItem}
        components={{
          Header,
          EmptyPlaceholder: EmptyState,
        }}
        className="h-full w-full overflow-x-hidden"
      />
    </DefaultLayout>
  );
}

function InsightHeader({
  campaignData,
  campaignLink,
}: {
  campaignData: FragmentType<InsightHeaderFragmentDoc>;
  campaignLink: string;
}) {
  const {
    title,
    coverImage,
    initialReleaseImageUrl,
    releaseDate,
    description,
    newSubscriptionCount,
    receiptCount,
    artist,
  } = getFragment(InsightHeaderFragmentDoc, campaignData);

  const coverImageUrl = coverImage?.url ?? initialReleaseImageUrl ?? artist.profileImage?.url;

  return (
    <View className="mt-6 flex flex-col items-start justify-start md2:mt-8">
      <View className="flex w-full flex-col items-start justify-start gap-4">
        <button
          className="w-full appearance-none border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
          onClick={() => window.open(campaignLink, '_blank')}
        >
          <View className="flex w-full flex-row items-center justify-start gap-2">
            <View
              className={twMerge(
                'flex h-[56px] w-[56px] flex-shrink-0 items-center justify-center rounded-md',
                !!coverImageUrl ? 'bg-transparent' : 'bg-vault_text',
              )}
            >
              {coverImageUrl && (
                <Image
                  src={coverImageUrl}
                  alt="Cover Image"
                  className="h-full w-full rounded-md object-cover"
                />
              )}
            </View>
            <View className="flex flex-col items-start justify-start gap-1">
              <View className="flex items-center">
                <Text className="line-clamp-1   text-left font-title text-[29px] font-medium text-vault_text">
                  {title}
                </Text>
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  className="ml-2 text-[18px] text-vault_text"
                />
              </View>
              {releaseDate != null && (
                <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-60">
                  {formatDateString({ date: releaseDate, format: 'month_day_year' })}
                </Text>
              )}
            </View>
          </View>
        </button>
        {!!description && (
          <Text className="font-base text-[14px]/[18px] font-normal text-vault_text opacity-50">
            {description}
          </Text>
        )}
      </View>
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(receiptCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">Total receipts</Text>
        </View>
        <View className="flex flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10 p-3">
          <Text className="font-title text-[32px] font-medium text-vault_text">
            {millify(newSubscriptionCount, { lowercase: true })}
          </Text>
          <Text className="text-vault_text">New signups</Text>
        </View>
      </View>
    </View>
  );
}

function InsightHeaderSkeleton() {
  return (
    <View className="mt-6 flex flex-col items-start justify-start md2:mt-8">
      <View className="flex flex-col items-start justify-start gap-4">
        <View className="flex flex-row items-center justify-start gap-2">
          <LoadingSkeleton className="h-[56px] w-[56px] rounded-full" withVaultTheme />
          <View className="flex flex-col items-start justify-start gap-1">
            <LoadingSkeleton className="h-[35px] w-[200px] rounded-md" withVaultTheme />
            <LoadingSkeleton className="h-[17px] w-[150px] rounded-md" withVaultTheme />
          </View>
        </View>
      </View>
      <View className="my-8 flex w-full flex-row items-center justify-start gap-2">
        <LoadingSkeleton
          className="flex h-[93px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10"
          withVaultTheme
        />
        <LoadingSkeleton
          className="flex h-[93px] flex-1 flex-col gap-3 rounded-md border border-solid border-vault_text border-opacity-10"
          withVaultTheme
        />
      </View>
    </View>
  );
}
