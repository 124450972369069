import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import * as z from 'zod';

const GOOGLE_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

if (!GOOGLE_API_KEY) {
  throw new Error('VITE_GOOGLE_MAPS_API_KEY is not defined');
}

const GeocoderResultSchema = z.object({
  label: z.string(),
  value: z
    .object({
      place_id: z.string(),
    })
    .passthrough(),
});

export function useSingleLocationSelect({
  initialLocation,
  placeholder,
  className,
  setIsKeyboardOpen,
  onLocationChange,
  onBlur,
  error,
}: {
  initialLocation: {
    placeId: string;
    label: string;
  } | null;
  placeholder: string;
  className?: string;
  setIsKeyboardOpen: ((isOpen: boolean) => void) | null;
  onLocationChange?: (value: typeof GeocoderResultSchema._output | null) => void;
  onBlur?: () => void;
  error?: boolean;
}) {
  const initialLocationPlaceId = initialLocation?.placeId;
  const initialLocationLabel = initialLocation?.label;
  const [focus, setFocus] = useState(false);

  const [locationValue, setLocationValue] = useState<typeof GeocoderResultSchema._output | null>(
    () =>
      initialLocationPlaceId && initialLocationLabel
        ? { label: initialLocationLabel, value: { place_id: initialLocationPlaceId } }
        : null,
  );

  useEffect(() => {
    setLocationValue(prev =>
      prev?.value.place_id === initialLocationPlaceId && prev?.label === initialLocationLabel
        ? prev
        : initialLocationPlaceId && initialLocationLabel
          ? { label: initialLocationLabel, value: { place_id: initialLocationPlaceId } }
          : null,
    );
  }, [initialLocationLabel, initialLocationPlaceId]);

  const locationSelect = (
    <>
      <GooglePlacesAutocomplete
        apiKey={GOOGLE_API_KEY}
        apiOptions={{
          language: 'en',
        }}
        autocompletionRequest={{
          types: ['locality'],
        }}
        selectProps={{
          value: locationValue,
          className: clsx('text-white', className),
          onChange(value) {
            const parsedValue = value && GeocoderResultSchema.parse(value);
            // We need zod parsing due to missing proper typing
            setLocationValue(parsedValue);
            onLocationChange?.(parsedValue);
          },
          isClearable: true,
          placeholder,
          noOptionsMessage({ inputValue }) {
            return inputValue.trim().length
              ? 'Location could not be found'
              : 'Search for a location';
          },
          classNames: {
            dropdownIndicator: () => '!hidden',
            indicatorSeparator: () => '!hidden',
            control: () =>
              clsx(
                '!shadow-none !cursor-pointer !border-0 !border-base800',
                error ? '!bg-destructive50' : '!bg-[#0e1213]',
                focus && (error ? '!shadow-[0_0_0_1px_#EF4444]' : '!shadow-[0_0_0_1px_#1F1F1F]'),
              ),
            input: () => '!cursor-text !text-white',
            option: () => '!cursor-pointer !bg-[#0e1213]',
            valueContainer: () => clsx(error && '!bg-destructive50', '!text-white'),
            menuList: () => '!bg-[#0e1213] !border-white !border-2',
            singleValue: () => '!text-white',
          },
          onFocus: () => {
            setIsKeyboardOpen?.(true);
            setFocus(true);
          },
          onBlur: () => {
            setIsKeyboardOpen?.(false);
            setFocus(false);
            onBlur?.();
          },
        }}
      />
    </>
  );

  return {
    locationValue,
    locationSelect,
    setLocationValue,
  };
}
