import { useEffect } from 'react';
import { captureException } from '@sentry/react';
import { useNavigate } from 'react-router';
import { useGate } from 'statsig-react';
import { gql } from '@soundxyz/gql-string';
import { FEATURE_GATES } from '../../../constants/flagConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation, useQuery } from '../../../graphql/client';
import {
  GetCampaignSpotifyPresaveStatusDocument,
  type MusicCampaignViewFragment,
  SpotifyPresaveDocument,
  ThirdPartyPlatform,
} from '../../../graphql/generated';
import {
  getRandomSpotifyState,
  SpotifyConnectState,
  useLinkSpotifyAccount,
  useSpotifyAuth,
} from '../../../hooks/spotify/useSpotifyAuth';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';

gql(/* GraphQL */ `
  query GetCampaignSpotifyPresaveStatus($input: QueryReleaseCampaignSpotifyPresaveStatusInput!) {
    releaseCampaignSpotifyPresaveStatus(input: $input) {
      id
      status
    }
  }

  mutation SpotifyPresave($input: MutationSpotifyPresaveInput!) {
    spotifyPresave(input: $input) {
      __typename
      ... on MutationSpotifyPresaveSuccess {
        __typename
      }

      ... on Error {
        __typename
        message
      }
    }
  }
`);

export function useSpotifyPresaveCampaign({
  campaign,
}: {
  campaign: MusicCampaignViewFragment | null | undefined;
}) {
  const { loggedInUser } = useAuthContext();

  const { value: spotifyConnectEnabled } = useGate(FEATURE_GATES.SPOTIFY_CONNECT);

  const linkSpotify = useLinkSpotifyAccount();

  const { openToast } = useToast();
  const navigate = useNavigate();

  const spotifyAuth = useSpotifyAuth({
    enabled: spotifyConnectEnabled,
  });

  const { value } = SpotifyConnectState.useStore();

  const justConnectedSpotify = value?.justConnected;

  const isSpotifyAccountLinked = !!loggedInUser?.spotifyAuthConnection?.spotifyUserId;
  const isSpotifyAccountConnected = spotifyAuth.type === 'already-connected';

  const { data, isLoading: loadingPresaveStatus } = useQuery(
    GetCampaignSpotifyPresaveStatusDocument,
    {
      staleTime: 0,
      variables: !!campaign?.id && {
        input: {
          releaseCampaignId: campaign.id,
          spotifyAuthUserCode: spotifyAuth.authCode,
        },
      },
      enabled: !!spotifyAuth.authCode || !!loggedInUser?.id,
    },
  );

  const { mutateAsync: presaveSpotifyMutation, isLoading: isPresaving } = useMutation(
    SpotifyPresaveDocument,
    {
      retry: 5,
    },
  );

  const presave = useStableCallback(async () => {
    if (!campaign) return;

    // If logged in + connected = link account first
    // If logged in + linked = pre-save
    // If not logged in but connected = pre-save
    const alreadyConnected =
      (isSpotifyAccountLinked || isSpotifyAccountConnected) && !!spotifyAuth.authCode;

    const hasToLink = isSpotifyAccountConnected && !isSpotifyAccountLinked && !!loggedInUser?.id;

    if (alreadyConnected) {
      try {
        if (hasToLink) {
          await linkSpotify();
        }

        const { data } = await presaveSpotifyMutation({
          input: {
            releaseCampaignId: campaign.id,
            spotifyAuthUserToken: !!loggedInUser?.id ? undefined : spotifyAuth.authCode,
          },
        });

        if (data.spotifyPresave.__typename === 'NotFoundError') {
          openToast({
            text: 'The event campaign does not exist.',
            variant: 'error',
          });
        }

        if (data.spotifyPresave.__typename === 'MutationSpotifyPresaveSuccess') {
          trackEvent({
            type: EVENTS.PRESAVE,
            properties: {
              artistHandle: campaign.artist.linkValue,
              campaignId: campaign.id,
              platform: ThirdPartyPlatform.Spotify,
            },
          });

          navigate(
            artistNavigationPath(
              campaign.artist.linkValue,
              `/s/${campaign.linkValue}/claim`,
              'step=claim&source=spotify',
            ),
          );

          openToast({
            text: 'The song has been pre-saved.',
            variant: 'success',
          });
        }
      } catch (error) {
        captureException(error, {
          tags: {
            feature: 'Presave Campaign',
          },
          extra: {
            artistHandle: campaign.artist.linkValue,
            campaignId: campaign.id,
            platform: ThirdPartyPlatform.Spotify,
          },
        });
        openToast({
          text: 'There was an error pre-saving the release',
          variant: 'error',
        });
      }

      return;
    }

    window.open(spotifyAuth.link, '_blank', 'noopener,noreferrer');
  });

  useEffect(() => {
    if (justConnectedSpotify && isSpotifyAccountConnected) {
      SpotifyConnectState.produceExistingState(
        draft => {
          draft.justConnected = false;
        },
        {
          code: null,
          state: getRandomSpotifyState(),
          justConnected: false,
        },
      );

      presave();
    }
  }, [justConnectedSpotify, navigate, isSpotifyAccountConnected, presave]);

  return {
    presave,
    isPresaving,
    presaveStatus: data?.data.releaseCampaignSpotifyPresaveStatus?.status,
    loadingPresaveStatus,
  };
}
