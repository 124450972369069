import { useMemo } from 'react';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useQuery } from '../../graphql/client';
import { ReleaseCampaignByIdDocument } from '../../graphql/generated';
import { useCopy } from '../../hooks/useCopy';
import type { EventCreateSuccessBottomsheetProps } from '../../types/bottomsheetTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';

export function EventCreateSuccessBottomsheet({ campaignId }: EventCreateSuccessBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();

  const { data, isLoading } = useQuery(ReleaseCampaignByIdDocument, {
    variables: {
      id: campaignId,
    },
    staleTime: 0,
    select(data) {
      return data.data.releaseCampaignByIdOrSlug;
    },
  });

  const link = useMemo(() => {
    const path = data?.linkValue ? `/s/${data.linkValue}` : `/s/${campaignId}`;

    return generateShareLink({
      artistLinkValue: data?.artist?.linkValue,
      path,
      inviteCode: null,
    });
  }, [campaignId, data?.artist.linkValue, data?.linkValue]);

  const { copy: onCopyLinkClick } = useCopy({
    text: link,
    successMessage: 'Campaign link copied to clipboard!',
  });

  if (!data && !isLoading) {
    closeBottomsheet();
    return null;
  }

  if (isLoading) {
    return <EventCreateSuccessBottomsheetSkeleton />;
  }

  return (
    <View className="flex flex-col items-center justify-center">
      {data?.coverImage || data?.initialReleaseImageUrl || data?.artist.profileImage?.url ? (
        <Image
          className="mt-5 aspect-square w-24 rounded-xl"
          src={
            data?.coverImage?.url ||
            data?.initialReleaseImageUrl ||
            data?.artist.profileImage?.url ||
            ''
          }
          alt="campaign"
        />
      ) : (
        <UserPlaceholderImage
          id={crypto.randomUUID()}
          className="h-full w-full object-cover opacity-75 blur-2xl filter"
        />
      )}
      <Text className="mt-7 text-center text-title-l font-medium text-vault_text">
        Congrats you're live!
      </Text>
      <Text className="mt-3 max-w-[300px] text-center text-base-m text-vault_text/50">
        {`Share this page so your fans can start ${data?.currentState === 'PRESAVE' ? 'pre-saving' : 'streaming'} your song.`}
      </Text>

      <Button
        className="mt-6 w-full"
        type="primary-themed"
        label="Copy link"
        leadingIcon={faLink}
        onClick={onCopyLinkClick}
      />
      <Text
        className="my-6 cursor-pointer text-center text-base-m font-medium text-vault_text"
        onClick={closeBottomsheet}
      >
        Not now
      </Text>
    </View>
  );
}

export function EventCreateSuccessBottomsheetSkeleton() {
  return (
    <View className="flex flex-col items-center justify-center">
      <LoadingSkeleton className="mt-5 aspect-square w-24 rounded-xl" />
      <LoadingSkeleton className="mt-7 h-8 w-48" />
      <LoadingSkeleton className="mt-3 h-16 w-[300px]" />

      <LoadingSkeleton className="rounded mt-6 h-10 w-full rounded-full" />
      <LoadingSkeleton className="my-6 h-6 w-16 rounded-xl" />
    </View>
  );
}
