import React, { useEffect } from 'react';
import { captureMessage } from '@sentry/react';
import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio/react';
import { faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faUser } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPalette } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { deepTrim } from '@soundxyz/utils/src/string';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { COLOR } from '../../../constants/colorConstants';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { useToast } from '../../../contexts/ToastContext';
import {
  ArtistSettingsViewFragmentDoc,
  CustomizeVaultViewFragmentDoc,
  getFragment,
} from '../../../graphql/generated';
import { useEditProfile } from '../../../hooks/useEditProfile';
import { resetVaultTheme, setVaultTheme, VaultThemeStore } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { EVENTS } from '../../../types/eventTypes';
import { trackEvent } from '../../../utils/analyticsUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { ErrorView } from '../../error/ErrorView';
import { CustomizeProfile, CustomizeVaultSkeleton } from './CustomizeProfile';
import { CustomizeVaultTheme } from './CustomizeVaultTheme';
import { type CustomizeMenuValidationSchema } from './schema';
import { useCustomize } from './useCustomize';

export function CustomizeVaultMenu({ vaultId }: { vaultId: string }) {
  const { isVaultCustomizeOpen, closeVaultCustomization, isClosingCustomizeMenu } =
    useMenuContainer();
  const { isDesktop } = useWindow();
  const { pathname } = useLocation();
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const { openToast } = useToast();

  const { updateArtistProfile } = useEditProfile();

  const {
    accents,
    activeTab,
    artistProfile,
    customizeVault,
    errors,
    formAccentColor,
    formBackgroundColor,
    formName,
    handleSubmit,
    isActionPending,
    isCustomizing,
    isError,
    isLoading,
    isSubmitting,
    refetchArtistProfile,
    refetchVault,
    register,
    reset,
    setActionPending,
    setActiveTab,
    setTemporaryLogoUrl,
    setTemporaryProfileImageUrl,
    setValue,
    temporaryLogoUrl,
    temporaryProfileImageUrl,
    vault,
  } = useCustomize({ vaultId });

  const vaultTheme = useSnapshot(VaultThemeStore);

  const onSubmit = async (props: CustomizeMenuValidationSchema) => {
    if (!vault || !artistProfile) return;

    trackEvent({
      type: EVENTS.CUSTOMIZE_VAULT,
      properties: { vaultId: vault.id },
      pathname,
    });

    trackEvent({
      type: EVENTS.EDIT_ARTIST_PROFILE,
      properties: { artistId: artistProfile.id },
      pathname,
    });

    const [editProfileResult, customizeResult] = await Promise.all([
      updateArtistProfile({
        input: {
          name: deepTrim(props.name),
          socials: {
            customWebsiteUrl: props.customWebsiteUrl?.trim() || null,
            spotifyUrl: props.spotifyUrl?.trim() || null,
            instagramHandle: props.instagramHandle?.trim() || null,
            tiktokHandle: props.tiktokHandle?.trim() || null,
          },
          artistId: artistProfile.id,
        },
      }),
      customizeVault({
        input: {
          vaultId: vault.id,
          profileImageId: props.profileImageMediaId,
          accentColor: props.accentColor ?? vault.accentColor,
          backgroundColor: props.backgroundColor ?? vault.backgroundColor,
          logoImageId: props.logoMediaId,
          shouldRemoveLogo: props.shouldRemoveLogo,
        },
      }),
    ]);

    if (
      customizeResult.data.customizeVault.__typename === 'MutationCustomizeVaultSuccess' &&
      editProfileResult.data.updateArtistProfile.__typename === 'MutationUpdateArtistProfileSuccess'
    ) {
      resetVaultTheme({ vaultId });

      // Update the form state
      const updatedProfileFragment = getFragment(
        ArtistSettingsViewFragmentDoc,
        editProfileResult.data.updateArtistProfile.data,
      );

      const updatedCustomizationFragment = getFragment(
        CustomizeVaultViewFragmentDoc,
        customizeResult.data.customizeVault.data,
      );

      reset({
        ...updatedProfileFragment,
        ...updatedCustomizationFragment,
        profileImageMediaId: updatedCustomizationFragment.artistProfile?.profileImage?.id ?? null,
        shouldRemoveLogo: false,
      });

      openToast({
        text: 'Customization saved successfully!',
        variant: 'success',
      });
    } else if (
      editProfileResult.data.updateArtistProfile.__typename === 'UsernameUnavailableError'
    ) {
      captureMessage('UsernameUnavailableError in CustomizeVaultMenu', {
        extra: {
          data: editProfileResult.data,
          artistProfile,
        },
        level: 'info',
      });
      openToast({
        text: 'Username is not available, please try a different username',
        variant: 'error',
      });
    } else if (editProfileResult.data.updateArtistProfile.__typename === 'ValidationError') {
      captureMessage('ValidationError in CustomizeVaultMenu', {
        extra: {
          data: editProfileResult.data,
          artistProfile,
        },
        level: 'warning',
      });
      openToast({
        text: editProfileResult.data.updateArtistProfile.message,
        variant: 'error',
      });
    } else {
      openToast({
        text: 'Failed to save Customization. Please try again.',
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    // preview form logic mirroring useVaultTheme
    const backgroundColor: string =
      formBackgroundColor || vaultTheme.backgroundColor || COLOR.black;
    const accentColor: string = formAccentColor || vaultTheme.accentColor || COLOR.yellow100;
    const name = formName || vaultTheme.name;

    setVaultTheme({
      newAccentColor: accentColor,
      newBackgroundColor: backgroundColor,
      newLogoUrl: temporaryLogoUrl ?? null,
      newProfileImageUrl: temporaryProfileImageUrl ?? null,
      newName: name,
    });
  }, [
    formAccentColor,
    formBackgroundColor,
    formName,
    temporaryLogoUrl,
    temporaryProfileImageUrl,
    vaultTheme.accentColor,
    vaultTheme.backgroundColor,
    vaultTheme.name,
  ]);

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const isDesktop = currentWidth >= 768;

      if (isDesktop) {
        closeBottomsheet();
      } else {
        openBottomsheet({
          type: BOTTOMSHEET_TYPES.CUSTOMIZE_VAULT,
          shared: {
            preventOutsideAutoClose: true,
            preventSwipeToDismiss: true,
            hidePulleyBar: true,
            hideCloseBottomsheetButton: true,
            showFullScreen: true,
          },
          customizeVaultBottomsheetProps: {
            vaultId,
            onClose: () => closeVaultCustomization({ showConfirm: true }),
            onSave: () => resetVaultTheme({ vaultId }),
          },
        });
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [closeBottomsheet, closeVaultCustomization, isDesktop, openBottomsheet, vaultId]);

  if (!isVaultCustomizeOpen || !isDesktop) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'no-scrollbar absolute bottom-0 left-0 top-0 box-border flex flex-col',
        'z-above4 items-start overflow-y-scroll bg-black',
        'animate-openMenu border-0 border-r border-solid border-r-white/5 px-6 py-10 md:w-[389px]',
        isClosingCustomizeMenu && 'animate-closeMenu',
      )}
    >
      <View className="mb-6 flex w-full flex-row items-center justify-between">
        <Text className="font-title text-title-l text-white">Customize vault</Text>
        <Button
          leadingIcon={faXmark}
          iconOnly
          onClick={() => closeVaultCustomization({ showConfirm: true })}
          disabled={isActionPending}
          label="Close"
          className="text-[24px] text-white"
          disabledClassName="opacity-50 cursor-not-allowed"
        />
      </View>

      <View className="mb-6 flex w-full items-center justify-center gap-2">
        <Button
          leadingIcon={faUser}
          label="Profile"
          className={twMerge(
            'h-[44px] w-1/2 rounded-full px-4 py-3 text-white',
            activeTab === 'profile'
              ? 'bg-base700'
              : 'border border-solid border-base700 bg-transparent opacity-30',
          )}
          onClick={() => setActiveTab('profile')}
          disabled={isActionPending}
        />
        <Button
          leadingIcon={faPalette}
          label="Theme"
          className={twMerge(
            'h-[44px] w-1/2 rounded-full px-4 py-3 text-white',
            activeTab === 'theme'
              ? 'bg-base700'
              : 'border border-solid border-base700 bg-transparent opacity-30',
          )}
          onClick={() => setActiveTab('theme')}
          disabled={isActionPending}
        />
      </View>

      <View className="flex h-full w-full flex-col justify-between">
        {isError ? (
          <ErrorView
            onRetryClick={() => {
              refetchVault();
              refetchArtistProfile();
            }}
            withVaultTheme={false}
            loggingType="customize_vault_menu"
          />
        ) : isLoading || vault == null ? (
          <CustomizeVaultSkeleton />
        ) : (
          <>
            <CustomizeVaultTheme
              setActionPending={setActionPending}
              isVisible={activeTab === 'theme'}
              setTemporaryLogoUrl={setTemporaryLogoUrl}
              temporaryLogoUrl={temporaryLogoUrl}
              vault={vault}
              isCustomizing={isCustomizing}
              setValue={setValue}
              isSubmitting={isSubmitting}
              accents={accents}
              formBackgroundColor={formBackgroundColor}
              formAccentColor={formAccentColor}
            />

            <CustomizeProfile
              isVisible={activeTab === 'profile'}
              setTemporaryProfileImageUrl={setTemporaryProfileImageUrl}
              temporaryProfileImageUrl={temporaryProfileImageUrl}
              setActionPending={setActionPending}
              vault={vault}
              isCustomizing={isCustomizing}
              isSubmitting={isSubmitting}
              setValue={setValue}
              errors={errors}
              register={register}
            />
          </>
        )}

        <Button
          buttonType="submit"
          type="primary"
          label="Save"
          onClick={handleSubmit(onSubmit)}
          disabled={isActionPending}
          loading={isSubmitting}
          disabledClassName="opacity-50"
          className="mt-6 w-full bg-white"
        />
      </View>
    </View>
  );
}
